import { query as queryUsers, queryCurrent, changePassword, update, query } from '@/services/user';

export default {
  namespace: 'user',

  state: {
    list: [],
    currentUser: {},
    permissionAction: {},
  },

  effects: {
    *fetch(_, { call, put }) {
      const response = yield call(queryUsers);
      yield put({
        type: 'save',
        payload: response,
      });
    },
    *fetchCurrent(_, { call, put }) {
      const response = yield call(queryCurrent);
      // setAuthorityByApi(response.permission);
      // reloadAuthorized();
      yield put({
        type: 'saveCurrentUser',
        payload: response,
      });
    },
    *update({ payload }, { call, put }) {
      // console.log(payload);
      const response = yield call(update, 'me', '', payload);
      // console.log(response);
      yield put({
        type: 'save',
        payload: response,
      });
      // if(callback) callback();
    },
    *changePassword({ payload }, { call }) {
      const response = yield call(changePassword, payload);
      return response;
    },
    *fetchDetail({ payload }, { call, put }) {
      const response = yield call(query, `me`, '', payload);
      yield put({
        type: 'saveDetail',
        payload: response,
      });
    },
  },

  reducers: {
    save(state, action) {
      return {
        ...state,
        list: action.payload,
      };
    },
    saveDetail(state, action) {
      return {
        ...state,
        customer: action.payload,
      };
    },
    saveCurrentUser(state, action) {
      return {
        ...state,
        permissionAction: action.payload.permissionAction,
        currentUser:
          {
            ...action.payload,
            avatar: 'https://gw.alipayobjects.com/zos/rmsportal/BiazfanxmamNRoxxVxka.png',
          } || {},
      };
    },
    changeNotifyCount(state, action) {
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          notifyCount: action.payload.totalCount,
          unreadCount: action.payload.unreadCount,
        },
      };
    },
  },
};
