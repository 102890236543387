export const dva = {
  config: {
    onError() {
      // e.preventDefault();
      // console.error('error', e.response);
    },
  },
  plugins: [],
};
export default dva;
