export default {
  'app.merchant.dispute_id': '事件编号',
  'app.merchant.all': '全部',
  'app.merchant.account_name': '帐户名',
  'app.merchant.account.alias_name': '帐户名',
  'app.merchant.currency': '货币',
  'app.merchant.buyer_name': '买家姓名',
  'app.merchant.dispute_details.buyer_name': '买家姓名',
  'app.merchant.channel': '争议渠道',
  'app.merchant.dispute_details.dispute_channel': '争议渠道',
  'app.merchant.invoice_number': '账单号',
  'app.merchant.dispute_details.invoice_number': '账单号',
  'app.merchant.invoice_id': '账单号',
  'app.merchant.payer_email': '买家邮箱',
  'app.merchant.dispute_details.payer_email': '买家邮箱',
  'app.merchant.t_id': '交易号',
  'app.merchant.user_id': '客服工号',
  'app.merchant.timezone': '时区',
  'app.merchant.date_start': '开始日期',
  'app.merchant.date_end': '结束日期',
  'app.merchant.group_by_column': '查询纬度',
  'app.merchant.is_vssku_or_tssku': '虚拟sku查询',
  'app.merchant.people': '人员',
  'app.merchant.family': '家族',
  'app.merchant.pageNum': '页数',
  'app.merchant.pageSize': '每页数量',

  'app.merchant.transaction_id': '交易号',
  'app.merchant1.transaction_id': 'Transaction ID',
  'app.merchant1.invoice_id': 'Invoice ID',
  'app.merchant1.payer_email': 'Buyer Email',
  'app.merchant1.dispute_id': 'Dispute ID',
  'app.merchant1.reference_id': 'Associated order',
  'app.merchant.dispute_details.transaction_id': '交易号',
  'app.merchant.life_cycle_stage': '事件类型',
  'app.merchant.dispute_details.dispute_life_cycle_stage': '事件类型',
  'app.merchant.dispute_life_cycle_stage': '事件类型',
  'app.merchant.export_id': '导出任务编号',
  'app.merchant.dispute_state': '是否解决',
  'app.merchant.end_created_at': '结束开立日期',
  'app.merchant.end_dispute_amount': '结束金额',
  'app.merchant.end_updated_at': '结束更新时间',
  'app.merchant.updated_at': '更新时间', // 暂时
  'app.merchant.is_read': '是否已读',
  'app.merchant.reason': '原因',
  'app.merchant.seller_response_due_date': '到期日',
  'app.merchant.start_seller_response_due_date': '起始到期日',
  'app.merchant.end_seller_response_due_date': '结束到期日',
  'app.merchant.start_created_at': '起始开立日期',
  'app.merchant.start_dispute_amount': '起始金额',
  'app.merchant.start_updated_at': '起始更新日期',
  'app.merchant.status': '当前状态',
  'app.merchant.sort': '排序方式',
  'app.merchant.sort_type': '排序方式',
  'app.merchant.start_date': '开始日期',
  'app.merchant.end_date': '结束日期',

  'app.merchant.1': '是',
  'app.merchant.0': '否',
  'app.merchant.ASC': '升序',
  'app.merchant.DESC': '降序',
  'app.merchant.CHARGEBACK': '补偿申请',
  'app.merchant.INQUIRY': '消息',
  'app.merchant.PRE_ARBITRATION': '预仲裁',
  'app.merchant.ARBITRATION': '仲裁',
  'app.merchant.INTERNAL': 'Paypal事件',
  'app.merchant.EXTERNAL': '发卡行或银行',
  'app.merchant.WAITING_FOR_SELLER_RESPONSE': '需要回复',
  'app.merchant.UNDER_REVIEW': '审查中',
  'app.merchant.WAITING_FOR_BUYER_RESPONSE': '等待对方回复',
  'app.merchant.MERCHANDISE_OR_SERVICE_NOT_RECEIVED': '未收到商品',
  'app.merchant.MERCHANDISE_OR_SERVICE_NOT_AS_DESCRIBED': '物品与商品描述不符',
  'app.merchant.UNAUTHORISED': '未经授权的交易',
  'app.merchant.PROBLEM_WITH_REMITTANCE': '汇款问题',
  'app.merchant.PROBLEM_WITH_SETTLEMENT': '结算问题',
  'app.merchant.OTHER': '其他',

  'app.merchant.RESOLVED': '已解决',
  'app.merchant.UN RESOLVED': '未解决',

  'app.merchant.users.zh_alias_name': '账户名',
  'app.merchant.accounts_name': '操作人员',
  'app.merchant.accounts.alias_name': '操作人员',
  'app.merchant.users_zh_name': '操作人员',
  'app.merchant.action': '审计类型',
  'app.merchant.manage_dispute': '管理争议',
  'app.merchant.refund': '退款',
  'app.merchant.disputes': '争议',
  'app.merchant.account_id': 'PP账号',
  'app.merchant.transactions': '交易',
  'app.merchant.audits': '审计',

  'app.merchant.submitted': '已提交',
  'app.merchant.running': '生成中',
  'app.merchant.complete': '已完成',
  'app.merchant.is_show': '是否显示',
  // 争议中心 生成导出任务 可排序的3个字段
  'app.merchant.disputes.created_at': '开立日期',
  'app.merchant.disputes.dispute_id': '争议编号',
  'app.merchant.disputes.updated_at': '最后更新时间',

  // 审计状态
  'app.merchant.success': '成功',
  'app.merchant.fail': '失败',
  'app.merchant.wait': '等待',

  'app.merchant.reference_id': '关联订单',

  'app.merchant.handle': '事件操作',
  'app.merchant.handle.acknowledge_return_item': '通知PayPal',
  'app.merchant.handle.appeal_provide_tracking_number_evidence': '申诉，提供物流证据',
  'app.merchant.handle.appeal_settlement': '申诉，结算问题',
  'app.merchant.handle.chargeback_provide_tracking_number_evidence': '退单，提供物流证据',
  'app.merchant.handle.make_offer_part_pefund': '提议部分退款',
  'app.merchant.handle.provide_more_info': '提供更多信息',
  'app.merchant.handle.refund': '全额退款',
  'app.merchant.handle.refund_requested': '交易退款请求',
  'app.merchant.handle.refuse_submit_info': '拒绝补偿，提供信息',
  'app.merchant.handle.return_refund': '全额退款退还商品',
  'app.merchant.handle.send_message': '发送消息',

  'app.merchant.order_id': '订单编号',
  'app.merchant.orders': '订单',
  'app.merchant.sale_mails': '邮件',
  'app.merchant.user_zh_name': '操作人',
  'app.merchant.template': '邮件模板',
  'app.merchant.receiver_mail': '收件人',
  'app.merchant.shopify_email': '联系邮箱',
  'app.merchant.track_number': '货运单号',
  'app.merchant.track_number1': '内部单号',
  'app.merchant.vm_tracknum': '虚拟单号',
  'app.merchant.search_type': '导出类型',
  'app.merchant.search_value': '导出类型值',
  'app.merchant.is_merge_sku': '是否合并SKU',

  'app.merchant.modal.order_refund': '订单退款',
  'app.merchant.modal.': ' ',
  'app.merchant.modal.refund': '交易退款',
  'app.merchant.modal.manage_dispute': '管理争议',
  'app.merchant.modal.manage_xborder': '信用卡通道(1)',
  // 'app.merchant.modal.manage_xborder_dispute': '信用卡通道(1)工单',
  'app.merchant.modal.manage_saas_dispute': 'Saas争议',

  // 运营管理
  'app.merchant.payment_gateway': '支付方式',
  'app.merchant.department': '部门',
  'app.merchant.page': '页数',
  'app.merchant.page_size': '每页数量',
  'app.merchant.operates': '运营',
  'app.merchant.null': '暂无',
  'app.merchant.account_family': 'paypal家族',
  'app.merchant.account_email': 'paypal邮箱',
  'app.merchant.myshopify_domain': '店铺网址',

  // xborder工单
  'app.merchant.is_refund': '是否退款',
  'app.merchant.xborder_disputes': '信用卡通道(1)工单',
  'app.merchant.is_dishonour': '是否拒付',
  'app.merchant.is_dishonour_warn': '是否拒付预警',
  'app.merchant.date_type': '日期类型',
  'app.merchant.start': '开始日期',
  'app.merchant.end': '结束日期',
  'app.merchant.bill_email': '买家邮箱',
  'app.merchant.bill_name': '买家姓名',
  'app.merchant.title': '标题',
  'app.merchant.notice_status': '关注状态',
  'app.merchant.is_reply': '是否回复',
  'app.merchant.order_number': '商户订单号',
  'app.merchant.merchant_order_id': '商户订单号',
  'app.merchant.is_reply.0': '否',
  'app.merchant.is_reply.1': '是',
  'app.merchant.is_refund.0': '未退款',
  'app.merchant.is_refund.1': '全额退款',
  'app.merchant.is_refund.2': '部分退款',
  'app.merchant.is_dishonour.0': '未拒付',
  'app.merchant.is_dishonour.1': '已拒付',
  'app.merchant.is_dishonour_warn.0': '未拒付预警',
  'app.merchant.is_dishonour_warn.1': '已拒付预警',
  'app.merchant.notice_status.1': '比较紧急',
  'app.merchant.notice_status.2': '特别紧急',
  'app.merchant.notice_status.3': '正常询问',
  'app.merchant.notice_status.4': '已退款/已完成',
  'app.merchant.payer_name': '持卡人姓名',

  'app.merchant1.T0000': 'Order',
  'app.merchant1.T0002': 'Subscription order',
  'app.merchant1.T0003': 'Prepaid order',
  'app.merchant1.T0006': 'Order',
  'app.merchant1.T0007': 'Website order',
  'app.merchant1.T0011': 'Mobile order',
  'app.merchant1.T0106': 'Refund fee',
  'app.merchant1.T0114': 'Disputed fees',
  'app.merchant1.T0200': 'Exchange rate transfer in',
  'app.merchant1.T0201': 'Exchange rate transfer out',
  'app.merchant1.T0400': 'Withdrawal',
  'app.merchant1.T0800': 'Extra income',
  'app.merchant1.T0805': 'Seller compensation',
  'app.merchant1.T1105': 'Margin unfreeze',
  'app.merchant1.T1106': 'Dispute refund',
  'app.merchant1.T1107': 'Seller refund',
  'app.merchant1.T1108': 'Fees refund',
  'app.merchant1.T1110': 'Dispute freeze',
  'app.merchant1.T1111': 'Dispute unfreeze',
  'app.merchant1.T1116': 'Freeze return',
  'app.merchant1.T1200': 'Account adjustment',
  'app.merchant1.T1201': 'Refusal to pay',
  'app.merchant1.T1202': 'Refusal of payment refund',
  'app.merchant1.T1503': 'Temporary freeze',
  'app.merchant1.T1900': 'Usually adjusted',
  'app.merchant1.T2101': 'Normally frozen',
  'app.merchant1.T2102': 'Normally defrosted',
  'app.merchant1.T2103': 'Margin freeze',
  'app.merchant1.T2104': 'Margin unfreeze',
  'app.merchant1.T2105': 'review freeze',
  'app.merchant1.T2106': 'Review unfreeze',
  'app.merchant1.T2107': 'Order freeze',
  'app.merchant1.T2108': 'Order unfreeze',
  'app.merchant1.T0113': 'Partner fee',

  'app.merchant1.D': 'Deny transaction',
  'app.merchant1.F': 'Partial refund',
  'app.merchant1.P': 'Transaction pending',
  'app.merchant1.S': 'Transaction successful',
  'app.merchant1.V': 'Transaction refund',
  'app.merchant1.undefined': '-',
  'app.merchant1.01': 'Eligible',
  'app.merchant1.02': 'Unqualified',
  'app.merchant1.03': 'Partially meets the conditions',

  'app.merchant1.SHIPPED': 'SHIPPED',
  'app.merchant1.IN_PROCESS': 'IN_PROCESS',
  'app.merchant1.CANCELLED': 'CANCELLED',
  'app.merchant1.ON_HOLD': 'ON_HOLD',
  'app.merchant1.PROCESSED': 'PROCESSED',
  'app.merchant1.RETURNED': 'RETURNED',

  // 账号限制
  'app.account.proofIdModal': '上传带照片的证件',
  'app.account.businessInfoModal': '提供您的公司信息',
  'app.account.explainActivityOnAccountModal': '请提供有关特定交易活动的信息',
  'app.account.shippingProofModal': '提供履行证明',
  'app.account.invoiceProofModal': '请提供从供应商处进货的收据',
  'app.account.provideWebsiteInfoModal': '解决关联的PayPal账户存在的问题',

  'app.account.impact.pay': '使用PayPal付款',
  'app.account.impact.receive': '收款',
  'app.account.impact.refund': '退款给客户',
  'app.account.impact.send': '付款',
  'app.account.impact.withdraw': '从PayPal提现',

  'app.account.status.UN RESOLVED': '未解决事件',
  'app.account.status.WAITING_FOR_SELLER_RESPONSE': '正在等待回复事件',
  'app.account.status.RESOLVED': '已结束事件',
  'app.account.status.ELIGIBLE': '受卖家保障规则保护的事件',
  'app.account.status.ALL': '所有事件',
  'app.account.status.undefined': ' ',
  'app.account.status.dispute': '所有事件',
  'app.account.status.dispute_resolved': '已结束事件',
  'app.account.status.dispute_total': '所有事件',
  'app.account.status.dispute_rate': 'PayPal 争议费率',

  // 每日任务
  'daily.transactions': '交易记录',
  'daily.monthly_sales': '月销售额',
  'daily.': '',
};
